exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-board-js": () => import("./../../../src/pages/adminBoard.js" /* webpackChunkName: "component---src-pages-admin-board-js" */),
  "component---src-pages-assistance-js": () => import("./../../../src/pages/assistance.js" /* webpackChunkName: "component---src-pages-assistance-js" */),
  "component---src-pages-cab-js": () => import("./../../../src/pages/cab.js" /* webpackChunkName: "component---src-pages-cab-js" */),
  "component---src-pages-chat-js": () => import("./../../../src/pages/chat.js" /* webpackChunkName: "component---src-pages-chat-js" */),
  "component---src-pages-check-list-js": () => import("./../../../src/pages/checkList.js" /* webpackChunkName: "component---src-pages-check-list-js" */),
  "component---src-pages-clock-js": () => import("./../../../src/pages/clock.js" /* webpackChunkName: "component---src-pages-clock-js" */),
  "component---src-pages-crm-js": () => import("./../../../src/pages/crm.js" /* webpackChunkName: "component---src-pages-crm-js" */),
  "component---src-pages-doors-stage-js": () => import("./../../../src/pages/doorsStage.js" /* webpackChunkName: "component---src-pages-doors-stage-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-ghost-host-js": () => import("./../../../src/pages/ghostHost.js" /* webpackChunkName: "component---src-pages-ghost-host-js" */),
  "component---src-pages-house-keeping-js": () => import("./../../../src/pages/houseKeeping.js" /* webpackChunkName: "component---src-pages-house-keeping-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lost-and-found-js": () => import("./../../../src/pages/lostAndFound.js" /* webpackChunkName: "component---src-pages-lost-and-found-js" */),
  "component---src-pages-lost-js": () => import("./../../../src/pages/Lost.js" /* webpackChunkName: "component---src-pages-lost-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-notebook-js": () => import("./../../../src/pages/notebook.js" /* webpackChunkName: "component---src-pages-notebook-js" */),
  "component---src-pages-quick-play-js": () => import("./../../../src/pages/quickPlay.js" /* webpackChunkName: "component---src-pages-quick-play-js" */),
  "component---src-pages-register-form-js": () => import("./../../../src/pages/registerForm.js" /* webpackChunkName: "component---src-pages-register-form-js" */),
  "component---src-pages-room-change-js": () => import("./../../../src/pages/roomChange.js" /* webpackChunkName: "component---src-pages-room-change-js" */),
  "component---src-pages-single-page-js": () => import("./../../../src/pages/singlePage.js" /* webpackChunkName: "component---src-pages-single-page-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-user-page-js": () => import("./../../../src/pages/userPage.js" /* webpackChunkName: "component---src-pages-user-page-js" */)
}

