module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"My Sweet Hotel","short_name":"My Sweet Hotel Pro","start_url":"/","background_color":"#630c13","theme_color":"#630c13","display":"standalone","icon":"src/svg/mshPro-newLogo-transparent.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9c8e4717edd829ab577e77dd01126da4"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://1a9f3c36b4664949b6e9ef27b2182905@o1024943.ingest.sentry.io/5992588","sampleRate":0.7},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
